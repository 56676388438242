<template>
  <div class="container text-center mt-5">
    <div class="row">
      <div class="col-sm-9 mb-3">
        <label for="trip" class="form-label"
          >TRIP <b><span class="text-danger">*</span></b></label
        >
        <input
          v-model="trip"
          type="text"
          class="form-control"
          id="trip"
          placeholder="CITY 1, FL;CITY 2, TX;CITY 3, TX"
          @change="print_tt"
        />
      </div>
      <div class="col-sm-2 mb-3">
        <label for="price" class="form-label"
          >Price <b><span class="text-danger">*</span></b></label
        >
        <input v-model="price" type="number" class="form-control" id="price" />
      </div>
      <div class="col-sm-1 mb-3 pt-2">
        <button
          type="button"
          @click="calcPriceMi"
          class="btn btn-secondary mt-4"
        >
          CALC
        </button>
      </div>
      <div class="col-sm-9">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-4 mb-3">
                <label for="load" class="form-label"
                  >#LOAD <b><span class="text-danger">*</span></b></label
                >
                <input
                  v-model="load"
                  type="text"
                  class="form-control"
                  id="load"
                  placeholder="466515194"
                  @change="print_tt"
                  @keyup="print_tt"
                />
              </div>
              <div v-if="3 > 4" class="col-sm-3 mb-3">
                <label for="driver" class="form-label"
                  >DRIVER <b><span class="text-danger">*</span></b></label
                >
                <select
                  v-if="drivers.length"
                  v-model="driver"
                  id="driver"
                  class="form-select"
                  aria-label="Default select example"
                >
                  <option
                    v-for="item in drivers"
                    :key="item.HOSDriverId"
                    :value="item.HOSDriverId"
                  >
                    {{ item.Name }} {{ item.LastName }}
                  </option>
                </select>
              </div>
              <div class="col-sm-8 mb-3">
                <label for="note" class="form-label"
                  >Driver <b><span class="text-danger">*</span></b></label
                >
                <select
                  v-model="note"
                  id="note"
                  class="form-select"
                  aria-label="Default select example"
                  @change="print_tt"
                >
                  <option
                    v-for="(ditem, dindex) in drivers_notes"
                    :key="dindex"
                    :value="ditem"
                  >
                    {{ ditem }}
                  </option>
                </select>
              </div>
              <div class="col-sm-4 mb-3">
                <label for="eta" class="form-label"
                  >ETA <b><span class="text-danger">*</span></b></label
                >
                <select
                  v-model="eta"
                  id="eta"
                  class="form-select"
                  aria-label="eta"
                  @change="print_tt"
                >
                  <option
                    v-for="(item, index) in arrtimes"
                    :key="index"
                    :value="item"
                  >
                    {{ item }}
                  </option>
                </select>
              </div>
              <div class="col-sm-4 mb-3">
                <label for="dateinit" class="form-label">Init</label>
                <input
                  v-model="dateinit"
                  type="datetime-local"
                  class="form-control"
                  id="dateinit"
                  placeholder="2024-02-15 10:50:00"
                />
              </div>
              <div class="col-sm-4 mb-3">
                <label for="dateend" class="form-label">End</label>
                <input
                  v-model="dateend"
                  type="datetime-local"
                  class="form-control"
                  id="dateend"
                  placeholder="2024-02-15 10:50:00"
                />
              </div>
              <div class="col-sm-3 mb-3">
                <label for="rute" class="form-label">Rute</label>
                <input
                  v-model="rute"
                  type="text"
                  class="form-control"
                  id="rute"
                  disabled
                />
              </div>
              <div class="col-sm-3 mb-3">
                <label for="travel" class="form-label">TIME TRAVEL</label>
                <input
                  v-model="travel"
                  type="text"
                  class="form-control"
                  id="travel"
                  disabled
                />
              </div>
              <div class="col-sm-3 mb-3">
                <label for="milles" class="form-label">Milles</label>
                <input
                  v-model="milles"
                  type="number"
                  class="form-control"
                  id="milles"
                  disabled
                />
              </div>
              <div class="col-sm-3 mb-3">
                <label for="total" class="form-label">$ x mi</label>
                <input
                  v-model="total"
                  type="text"
                  class="form-control"
                  id="total"
                  disabled
                />
              </div>
              <div class="col-sm-12">
                <p v-html="traveltext"></p>
                <p v-html="travelgoal"></p>
              </div>
              <div class="col-sm-12 mb-3">
                <div class="d-grid gap-2">
                  <button
                    @click="saveTravel"
                    class="btn btn-success btn-sm"
                    type="button"
                  >
                    + ADD
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-3">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-12 mb-3">
                <label for="dreamxmi" class="form-label">RPM</label>
                <input
                  v-model="dreamxmi"
                  type="text"
                  class="form-control"
                  id="dreamxmi"
                />
              </div>
              <div class="col-sm-12 mb-3">
                <label for="dreamxprice" class="form-label">Price ~</label>
                <input
                  v-model="dreamxprice"
                  type="text"
                  class="form-control"
                  id="dreamxprice"
                  disabled
                />
              </div>
              <div class="col-sm-12 mb-3">
                <div class="d-grid gap-2">
                  <button
                    @click="calcDmprice"
                    class="btn btn-primary btn-sm"
                    type="button"
                  >
                    CALC
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-grid gap-2 mt-4">
          <button
            :disabled="!searchweek"
            @click="calcDmprice"
            class="btn btn-info btn-sm"
            type="button"
          >
            WEEK ALL
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div v-if="xlist.length" class="w-100 mt-5">
      <hr />
      <div class="row">
        <div class="col-sm-1"><h3 class="text-start">Loads</h3></div>
        <div class="col-sm-1">
          <select
            v-model="filter_view"
            id="filter_view"
            class="form-select"
            aria-label="View"
            @change="filterListLoads"
          >
            <option value="dashboard">Dashboard</option>
            <option value="loads">Loads</option>
          </select>
        </div>
        <div class="col-sm-3">
          <input
            v-model="searchload"
            type="text"
            class="form-control"
            placeholder="filter #load"
            @change="filterListLoads"
            @keyup="filterListLoads"
          />
        </div>
        <div class="col-sm-3">
          <select
            v-model="search_note"
            id="search_note"
            class="form-select"
            aria-label="Driver"
            @change="filterListLoads"
          >
            <option value="all">All drivers</option>
            <option
              v-for="(ditem, dindex) in drivers_notes"
              :key="dindex"
              :value="ditem"
            >
              {{ ditem }}
            </option>
          </select>
        </div>
        <div class="col-sm-1">
          <input
            v-model="searchweeki"
            type="text"
            class="form-control"
            placeholder="filter week init"
            @change="filterListLoads"
            @keyup="filterListLoads"
          />
        </div>
        <div class="col-sm-1">
          <input
            v-model="searchweek"
            type="text"
            class="form-control"
            placeholder="filter week end"
            @change="changeWeekEnd"
            @keyup="changeWeekEnd"
          />
        </div>
        <div class="col-sm-1 text-end">
          <button
            :disabled="search_note == 'all' || xlistfilter.length == 0"
            @click="weekDriverItem"
            class="btn btn-info btn-sm"
            type="button"
          >
            Calc week
          </button>
        </div>
        <div class="col-sm-1 text-end">
          <button
            v-if="3 > 4"
            @click="trashDates"
            class="btn btn-danger btn-sm"
            type="button"
          >
            Delete all
          </button>
        </div>
      </div>
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">DAY</th>
              <th scope="col">RUTE</th>
              <th scope="col">LOAD</th>
              <th scope="col">ETA</th>
              <th scope="col">PRICE</th>
              <th scope="col">MILLES</th>
              <th scope="col">RPM</th>
              <th scope="col">DATE</th>
              <th scope="col">DRIVER</th>
              <th scope="col">Location</th>
              <th scope="col">W</th>
              <th scope="col">DAY</th>
              <th scope="col">STATUS</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in xlistfilter" :key="index">
              <th scope="row">{{ index + 1 }}</th>
              <td>{{ item.init }}</td>
              <td>{{ getRuteX(item.rute) }}</td>
              <td>{{ item.load }}</td>
              <td>{{ item.eta }}</td>
              <td>{{ formatNumber(item.price) }}</td>
              <td>{{ formatNumber(item.milles) }}</td>
              <td>$ {{ xround(item.total) }}</td>
              <td>
                <small class="d-block">{{
                  item.dateinit.replace("T", " ")
                }}</small
                ><small class="d-block">{{
                  item.dateend.replace("T", " ")
                }}</small>
              </td>
              <td>{{ item.note.split(":")[0] ?? "" }}</td>
              <td>{{ getLastTrip(item.trip) }}</td>
              <td>{{ item.week }}</td>
              <td>
                {{ item.end }}
              </td>
              <td>
                <span :class="`badge text-bg-${getColorStatus(item.status)}`">{{
                  item.status
                }}</span>
              </td>
              <td>
                <button
                  @click="viewLoad(index)"
                  type="button"
                  class="btn btn-primary btn-sm me-3"
                >
                  VIEW
                </button>
                <button
                  @click="trashItemDate(index)"
                  type="button"
                  class="btn btn-warning btn-sm"
                >
                  DEL
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div
    class="modal fade"
    id="exampleModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    ref="modalEle"
  >
    <div class="modal-dialog modal-lg">
      <div v-if="selected_load" class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel">
            Details load <b>#{{ selected_load.load }}</b>
          </h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <p v-html="selected_load.traveltext"></p>
          <small><p v-html="selected_load.travelgoal"></p></small>
          <hr />
          <h3>Edit LOAD</h3>
          <div class="row mt-4">
            <div class="col-sm-4 mb-3">
              <label for="load_edit" class="form-label"
                >#LOAD <b><span class="text-danger">*</span></b></label
              >
              <input
                v-model="selected_load.load"
                type="text"
                class="form-control"
                id="load_edit"
                placeholder="#load"
                @change="save_editload"
                @keyup="save_editload"
              />
            </div>
            <div class="col-sm-4 mb-3">
              <label for="eta_edit" class="form-label"
                >ETA <b><span class="text-danger">*</span></b></label
              >
              <select
                v-model="selected_load.eta"
                id="eta_edit"
                class="form-select"
                aria-label="eta"
                @change="save_editload"
              >
                <option
                  v-for="(item, index) in arrtimes"
                  :key="index"
                  :value="item"
                >
                  {{ item }}
                </option>
              </select>
            </div>
            <div class="col-sm-4 mb-3">
              <label for="status_edit" class="form-label"
                >Status <b><span class="text-danger">*</span></b></label
              >
              <select
                v-model="selected_load.status"
                id="status_edit"
                class="form-select"
                aria-label="status"
                @change="save_editload"
              >
                <option
                  v-for="item in loadStatus"
                  :key="item.value"
                  :value="item.value"
                >
                  {{ item.value }}
                </option>
              </select>
            </div>
            <div class="col-sm-6 mb-3">
              <label for="dateinit_edit" class="form-label">Init</label>
              <input
                v-model="selected_load.dateinit"
                type="datetime-local"
                class="form-control"
                id="dateinit_edit"
                placeholder="2024-02-15 10:50:00"
                @change="save_editload"
                @keyup="save_editload"
              />
            </div>
            <div class="col-sm-6 mb-3">
              <label for="dateend_edit" class="form-label">End</label>
              <input
                v-model="selected_load.dateend"
                type="datetime-local"
                class="form-control"
                id="dateend_edit"
                placeholder="2024-02-15 10:50:00"
                @change="save_editload"
                @keyup="save_editload"
              />
            </div>
            <div class="col-sm-12 mb-3">
              <label for="note_edit" class="form-label"
                >Driver <b><span class="text-danger">*</span></b></label
              >
              <select
                v-model="selected_load.note"
                id="note_edit"
                class="form-select"
                aria-label="Default select example"
                @change="save_editload"
              >
                <option
                  v-for="(ditem, dindex) in drivers_notes"
                  :key="dindex"
                  :value="ditem"
                >
                  {{ ditem }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    id="exampleModalWeekDriver"
    tabindex="-1"
    aria-labelledby="exampleModalLabelWeekDriver"
    aria-hidden="true"
    ref="modalEleWeekDriver"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabelWeekDriver">
            Driver <b>{{ search_note.split(":")[0] }}</b>
          </h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <table v-if="xscoreweek.length" class="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Value</th>
                <th scope="col">Mi</th>
                <th scope="col">RPM</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in xscoreweek" :key="index">
                <td>{{ index + 1 }}</td>
                <td>{{ item.v }}</td>
                <td>{{ item.mi }}</td>
                <td>{{ item.rpm }}</td>
              </tr>
              <tr v-if="objscorewk">
                <td></td>
                <td>
                  <b>{{ objscorewk.p }}</b>
                </td>
                <td>
                  <b>{{ objscorewk.mi }}</b>
                </td>
                <td>
                  <b>{{ objscorewk.rpm }}</b>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    id="exampleModalWeekDrivers"
    tabindex="-1"
    aria-labelledby="exampleModalLabelWeekDrivers"
    aria-hidden="true"
    ref="modalEleWeekDriver"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabelWeekDrivers">
            Driver <b>{{ search_note.split(":")[0] }}</b>
          </h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <table v-if="xscoreweek.length" class="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Value</th>
                <th scope="col">Mi</th>
                <th scope="col">RPM</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in xscoreweek" :key="index">
                <td>{{ index + 1 }}</td>
                <td>{{ item.v }}</td>
                <td>{{ item.mi }}</td>
                <td>{{ item.rpm }}</td>
              </tr>
              <tr v-if="objscorewk">
                <td></td>
                <td>
                  <b>{{ objscorewk.p }}</b>
                </td>
                <td>
                  <b>{{ objscorewk.mi }}</b>
                </td>
                <td>
                  <b>{{ objscorewk.rpm }}</b>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    id="exampleModaltrash"
    tabindex="-1"
    aria-labelledby="exampleModalLabeltrash"
    aria-hidden="true"
    ref="modalEleTrash"
  >
    <div class="modal-dialog">
      <div v-if="selected_load" class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabeltrash">
            Load <b>#{{ selected_load.load }}</b>
          </h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          Seguro que desea eliminar la carga seleccionada?
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          <button type="button" @click="deleteItemDate" class="btn btn-danger">
            Yes, delete!
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import {
  simpleGet,
  simplePost,
  DepartTime,
  drivers_notes,
  loadStatus,
} from "../api/constants";
import { Modal } from "bootstrap";
import { v4 as uuidv4 } from "uuid";
//import axios from "axios";
// @ is an alias to /src

export default {
  name: "HomeView",
  components: {},
  setup() {
    const filter_view = ref("dashboard");
    const deleteindex_load = ref();
    const selected_load = ref();
    const searchload = ref();
    const search_note = ref("all");
    const searchweeki = ref();
    const searchweek = ref();
    const load_drivers = ref(false);
    const drivers = ref([]);
    const rute = ref(null);
    const trip = ref(null);
    const load = ref(null);
    const driver = ref(null);
    const eta = ref(null);
    const note = ref(null);
    const mempty = ref(null);
    const milles = ref(null);
    const price = ref(null);
    const travel = ref(null);
    const total = ref(0);
    const traveltext = ref("");
    const travelgoal = ref("");
    const dreamxmi = ref();
    const dreamxprice = ref();
    const xlist = ref([]);
    const xlistfilter = ref([]);
    const xlistfilterAll = ref([]);
    const xscoreweek = ref([]);
    const objscorewk = ref();
    const dateinit = ref(new Date());
    const dateend = ref(new Date());
    const arrtimes = ref(DepartTime);
    const daysOfWeek = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];
    let modalEle = ref(null);
    let thisModalObj = null;
    let modalEleTrash = ref(null);
    let modalEleWeekDriver = ref(null);
    let thisModalObjTrash = null;
    let thisModalObjWeekDriver = null;
    const o_loadedit = ref();

    onMounted(async () => {
      thisModalObj = new Modal(modalEle.value);
      thisModalObjTrash = new Modal(modalEleTrash.value);
      thisModalObjWeekDriver = new Modal(modalEleWeekDriver.value);
      //const xdata = localStorage.getItem("dhlist_fsc");
      //xlist.value = xdata ? JSON.parse(xdata) : [];
      xlist.value = await getXLoads();
      filterListLoads();
      const xsearchweek = localStorage.getItem("dweekendfl_fsc");
      searchweek.value = xsearchweek ? JSON.parse(xsearchweek) : "";

      const xdrivers = localStorage.getItem("xdrivers_fsc");
      drivers.value = xdrivers ? JSON.parse(xdrivers) : "";
      if (drivers.value) {
        drivers.value = drivers.value.filter((item) => item.IsActive == 1);
      } else {
        if (!load_drivers.value) {
          loadDvrAll();
          load_drivers.value = true;
        }
      }
      //---------------------------------------------------------------------
      /*const arr_auxiliar = [];
      xlist.value.forEach(async (item) => {
        //const init = getDayWeek(item.dateinit);
        //const weeki = getNumWeek(dateinit.value);
        //const week = getNumWeek(item.dateend);
        //let aux_item = item;
        //aux_item.weeki = weeki;
        //aux_item.week = week;
        //aux_item.status = "Completed";
        //arr_auxiliar.push(aux_item);
        console.log(item);
        await createXLoads(item);
      });
      xlist.value = arr_auxiliar;*/
      //localStorage.setItem("dhlist_fsc", JSON.stringify(xlist.value));
      //---------------------------------------------------------------------
      filterListLoads();
    });
    const getXLoads = async () => {
      const list_xloads = await simpleGet("xloads/simple");
      return list_xloads.data;
    };
    const createXLoads = async (item) => {
      const obj = await simplePost("xloads", item);
      return obj;
    };
    const updateXLoads = async (item) => {
      const obj = await simplePost("xloads/update/" + item.uuid, item);
      return obj.data;
    };
    const changeWeekEnd = () => {
      localStorage.setItem("dweekendfl_fsc", JSON.stringify(searchweek.value));
      filterListLoads();
    };
    const loadDvrAll = async () => {
      //here
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      const raw = JSON.stringify({
        RequestDTO: {
          PartnerId: 1,
          HOSClientId: 27652,
          ExternalClientId: 23627,
          HOSHomeBaseIds: [29281],
        },
      });
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      await fetch(
        "https://livetrack.atcompass.net/ws/WSHOS.asmx/GetHOSDrivers",
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          const o_all = result ? JSON.parse(result) : [];
          drivers.value = o_all.d;
          drivers.value = drivers.value.filter((item) => item.IsActive == 1);
          localStorage.setItem("xdrivers_fsc", JSON.stringify(drivers.value));
        })
        .catch((error) => console.error(error));
    };
    const calcPriceMi = () => {
      if (!trip.value || !price.value) {
        //here
        alert("Debe completar los campos");
      } else {
        calcTrips();
      }
    };
    const saveTravel = async () => {
      if (!trip.value || !price.value) {
        //here
        alert("Debe completar los campos");
      } else {
        const init = getDayWeek(dateinit.value);
        const end = getDayWeek(dateend.value);
        const weeki = getNumWeek(dateinit.value);
        const week = getNumWeek(dateend.value);
        const newItem = {
          //uuid: uuidv4(),
          rute: rute.value,
          trip: trip.value,
          load: load.value,
          driver: driver.value,
          eta: eta.value,
          note: note.value,
          milles: milles.value,
          price: price.value,
          travel: travel.value,
          traveltext: traveltext.value,
          travelgoal: travelgoal.value,
          total: total.value,
          dateinit: dateinit.value,
          dateend: dateend.value,
          weeki: weeki,
          week: week,
          init: init,
          end: end,
          status: "Pending",
        };
        await createXLoads(newItem);
        xlist.value = await getXLoads();
        filterListLoads();
        resetForm();
      }
    };
    const resetForm = () => {
      rute.value = null;
      trip.value = null;
      load.value = null;
      driver.value = null;
      note.value = null;
      milles.value = null;
      price.value = null;
      total.value = 0;
      travel.value = null;
      traveltext.value = null;
      travelgoal.value = null;
      dateinit.value = new Date();
      dateend.value = null;
    };
    const calcTrips = async () => {
      if (trip.value) {
        milles.value = 0;
        let tmiles = 0;
        let ttimes = 0;
        const tripaux = [];
        const arr = trip.value.split(";");
        if (arr.length > 1) {
          for (let i = 0; i < arr.length - 1; i++) {
            const d = await calcDist(arr[i], arr[i + 1]); //status
            tripaux.push({
              origin: arr[i],
              destination: arr[i + 1],
              distance: d.data.response.rows[0].elements[0].distance,
              duration: d.data.response.rows[0].elements[0].duration,
              status: d.data.response.rows[0].elements[0].status,
            });
            tmiles += d.data.response.rows[0].elements[0].distance.value;
            ttimes += d.data.response.rows[0].elements[0].duration.value;
          }
        }
        milles.value = Math.round(tmiles * 0.000621371192);
        travel.value = toHM(ttimes);
        const vmi = price.value / milles.value;
        total.value = Math.round((vmi + Number.EPSILON) * 100) / 100;
        //console.log("DIST:", tripaux);
        textTravelOut();
      }
    };
    const calcDist = async (a, b) => {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          origins: a,
          destinations: b,
        }),
        redirect: "follow",
      };
      const info = await fetch(
        "https://spartan.app.3dboosterstudio.com/api/maps",
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          const o_all = result ? JSON.parse(result) : [];
          return o_all;
        })
        .catch((error) => console.error(error));
      return info;
    };
    const trashDates = () => {
      xlist.value = [];
      localStorage.setItem("dhlist_fsc", JSON.stringify(xlist.value));
      filterListLoads();
    };
    const formatNumber = (value) => {
      const aux = new Intl.NumberFormat("en-us").format(value);
      return aux;
    };
    const xround = (value) => {
      return Math.round((value + Number.EPSILON) * 100) / 100;
    };
    const formatDate = (v) => {
      const d = new Date(v);
      return (
        [
          (d.getMonth() + 1).padLeft(),
          d.getDate().padLeft(),
          d.getFullYear().padLeft(),
        ].join("/") +
        " " +
        [
          d.getHours().padLeft(),
          d.getUTCMinutes().padLeft(),
          d.getUTCSeconds().padLeft(),
        ].join(":")
      );
    };
    const toHM = (totalSeconds) => {
      const totalMinutes = Math.floor(totalSeconds / 60);
      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;
      let dttime = "";
      if (hours) {
        dttime += hours + "h";
      }
      if (minutes) {
        if (dttime) {
          dttime += " ";
        }
        dttime += minutes + "m";
      }
      return dttime;
    };
    const textTravelOut = () => {
      print_tt();
    };
    const calcDmprice = () => {
      if (dreamxmi.value && milles.value) {
        dreamxprice.value = Math.round(dreamxmi.value * milles.value);
      }
    };
    const getDayWeek = (d) => {
      const date = new Date(d);
      const currentDayOfWeek = daysOfWeek[date.getDay()];
      return currentDayOfWeek;
    };
    const getNumWeek = (dx) => {
      const dxx = new Date(dx);
      let d = new Date(
        Date.UTC(dxx.getFullYear(), dxx.getMonth(), dxx.getDate())
      );
      d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
      var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
      var weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
      return weekNo;
    };
    const print_tt = () => {
      let tp_goal = "";
      if (trip.value) {
        const arr = trip.value.split(";");
        if (arr.length > 1) {
          let tp = "";
          for (let i = 0; i < arr.length; i++) {
            const auxl = arr[i].split(", ");
            const xtp = auxl.length > 1 ? auxl[1].substring(0, 2) : "xx";
            tp += i == 0 ? xtp : "-" + xtp;
            tp_goal += i == 0 ? arr[i] : " =" + i + "X=> " + arr[i];
          }
          console.log(tp);
          rute.value = tp;
        }
      }
      traveltext.value =
        rute.value +
        " <b>" +
        load.value +
        "</b>: " +
        note.value +
        "  <b>ETA " +
        eta.value +
        "</b>";
      //(We_06 ) $1300 (813 mi, with mi empty 1.60 x mi) 12h:45m travel
      //
      travelgoal.value =
        "(" +
        tp_goal +
        ") <b>$" +
        price.value +
        "</b> (<b>" +
        milles.value +
        "</b> mi, with mi empty <b>" +
        total.value +
        "</b> x mi) " +
        travel.value +
        " travel";
    };
    const viewLoad = (index) => {
      selected_load.value = xlistfilter.value[index];
      o_loadedit.value = selected_load.value.uuid;
      thisModalObj.show();
    };
    const save_editload = async () => {
      /*xlist.value.forEach(async (item) => {
        if (item.uuid == o_loadedit.value) {
          //here
        }
      });*/
      selected_load.value.init = getDayWeek(selected_load.value.dateinit);
      selected_load.value.end = getDayWeek(selected_load.value.dateend);
      selected_load.value.weeki = getNumWeek(selected_load.value.dateinit);
      selected_load.value.week = getNumWeek(selected_load.value.dateend);
      await datanotetravel();
    };
    const datanotetravel = async () => {
      let tp_goal = "";
      if (selected_load.value.trip) {
        const arr = selected_load.value.trip.split(";");
        if (arr.length > 1) {
          let tp = "";
          for (let i = 0; i < arr.length; i++) {
            const auxl = arr[i].split(", ");
            const xtp = auxl.length > 1 ? auxl[1].substring(0, 2) : "xx";
            tp += i == 0 ? xtp : "-" + xtp;
            tp_goal += i == 0 ? arr[i] : " =" + i + "X=> " + arr[i];
          }
          console.log(tp);
          selected_load.value.rute = tp;
        }
      }
      selected_load.value.traveltext =
        selected_load.value.rute +
        " <b>" +
        selected_load.value.load +
        "</b>: " +
        selected_load.value.note +
        "  <b>ETA " +
        selected_load.value.eta +
        "</b>";
      selected_load.value.travelgoal =
        "(" +
        tp_goal +
        ") <b>$" +
        selected_load.value.price +
        "</b> (<b>" +
        selected_load.value.milles +
        "</b> mi, with mi empty <b>" +
        selected_load.value.total +
        "</b> x mi) " +
        selected_load.value.travel +
        " travel";
      await updateXLoads(selected_load.value);
      xlist.value = await getXLoads();
      filterListLoads();
    };
    const trashItemDate = (index) => {
      console.log(selected_load.value);
      deleteindex_load.value = xlistfilter.value[index].uuid;
      selected_load.value = xlistfilter.value[index];
      thisModalObjTrash.show();
    };
    const deleteItemDate = async () => {
      const itemDel = selected_load.value;
      itemDel.status = "Deleted";
      await updateXLoads(itemDel);
      xlist.value = await getXLoads();
      /*xlist.value = xlist.value.filter(
        (item, iter) => item.uuid != selected_load.value.uuid
      );*/
      filterListLoads();
      thisModalObjTrash.hide();
    };
    const weekDriverItem = () => {
      //calc utilidades del filtro
      let total_mi = 0;
      let total_price = 0;
      let total_rpm = 0;
      xscoreweek.value = [];
      if (xlistfilterAll.value.length) {
        xlistfilterAll.value.forEach((item) => {
          const vmi = item.price / item.milles;
          const rpm = Math.round((vmi + Number.EPSILON) * 100) / 100;
          total_mi += item.milles;
          total_price += item.price;
          total_rpm += rpm;
          xscoreweek.value.push({
            mi: item.milles,
            v: item.price,
            rpm: item.total,
          });
        });
        //const avgrpm = total_rpm / xlistfilterAll.value.length;
        const xrpm = total_price / total_mi;
        total_rpm = Math.round((xrpm + Number.EPSILON) * 100) / 100;
        objscorewk.value = { mi: total_mi, p: total_price, rpm: total_rpm };
      }
      thisModalObjWeekDriver.show();
    };
    const getColorStatus = (status) => {
      //
      if (status == "Pending") {
        return "secondary";
      } else if (status == "Completed") {
        return "success";
      } else if (status == "In progress") {
        return "primary";
      } else if (status == "Cancelled") {
        return "danger";
      }
      return "warning";
    };
    const getLastTrip = (trip) => {
      const t = trip.split(";");
      return t[t.length - 1];
    };
    const getRuteX = (rute) => {
      const r = rute.split("-");
      return r.length > 2 ? rute.substring(3, rute.length) : rute;
    };
    const filterListLoads = () => {
      if (
        searchload.value &&
        searchweeki.value &&
        searchweek.value &&
        search_note.value != "all"
      ) {
        xlistfilter.value = xlist.value.filter(
          (item, iter) =>
            item.load.includes(searchload.value) &&
            item.weeki == searchweeki.value &&
            item.note == search_note.value &&
            item.week == searchweek.value
        );
      } else if (
        searchload.value &&
        searchweeki.value &&
        searchweek.value &&
        search_note.value == "all"
      ) {
        xlistfilter.value = xlist.value.filter(
          (item, iter) =>
            item.load.includes(searchload.value) &&
            item.weeki == searchweeki.value &&
            item.week == searchweek.value
        );
      } else if (
        !searchload.value &&
        searchweeki.value &&
        searchweek.value &&
        search_note.value != "all"
      ) {
        xlistfilter.value = xlist.value.filter(
          (item, iter) =>
            item.weeki == searchweeki.value &&
            item.note == search_note.value &&
            item.week == searchweek.value
        );
      } else if (
        !searchload.value &&
        searchweeki.value &&
        searchweek.value &&
        search_note.value == "all"
      ) {
        xlistfilter.value = xlist.value.filter(
          (item, iter) =>
            item.weeki == searchweeki.value && item.week == searchweek.value
        );
      } else if (searchload.value && search_note.value != "all") {
        xlistfilter.value = xlist.value.filter((item, iter) => {
          return (
            item.load.includes(searchload.value) &&
            item.note == search_note.value
          );
        });
      } else if (searchload.value) {
        xlistfilter.value = xlist.value.filter((item, iter) => {
          return item.load.includes(searchload.value);
        });
      } else if (searchweeki.value && search_note.value != "all") {
        xlistfilter.value = xlist.value.filter(
          (item, iter) =>
            item.weeki == searchweeki.value && item.note == search_note.value
        );
      } else if (searchweeki.value) {
        xlistfilter.value = xlist.value.filter(
          (item, iter) => item.weeki == searchweeki.value
        );
      } else if (searchweek.value && search_note.value != "all") {
        xlistfilter.value = xlist.value.filter(
          (item, iter) =>
            item.week == searchweek.value && item.note == search_note.value
        );
      } else if (searchweek.value) {
        xlistfilter.value = xlist.value.filter(
          (item, iter) => item.week == searchweek.value
        );
      } else if (search_note.value != "all") {
        xlistfilter.value = xlist.value.filter(
          (item, iter) => item.note == search_note.value
        );
      } else {
        xlistfilter.value = xlist.value;
      }
      xlistfilter.value.sort(function (a, b) {
        const dateA = new Date(a.dateend);
        const dateB = new Date(b.dateend);
        return dateA - dateB;
      });
      const arr_filtered = xlistfilter.value.sort(function (a, b) {
        const dateA = new Date(a.dateend);
        const dateB = new Date(b.dateend);
        return dateA - dateB;
      });
      xlistfilterAll.value = arr_filtered;
      xlistfilter.value = arr_filtered;
      if (filter_view.value == "dashboard") {
        const arr_inverse = arr_filtered.reverse();
        const includ = [];
        const filtedout = [];
        arr_inverse.forEach((element) => {
          if (!includ.includes(element.note)) {
            //includ
            includ.push(element.note);
            filtedout.push(element);
          }
        });
        xlistfilter.value = filtedout.reverse();
      }
      return xlistfilter.value;
    };
    return {
      calcDmprice,
      calcPriceMi,
      saveTravel,
      trashItemDate,
      deleteItemDate,
      trashDates,
      formatNumber,
      xround,
      formatDate,
      dreamxprice,
      dreamxmi,
      rute,
      trip,
      load,
      driver,
      drivers,
      eta,
      note,
      mempty,
      milles,
      price,
      travel,
      total,
      xlist,
      xlistfilter,
      toHM,
      traveltext,
      travelgoal,
      print_tt,
      arrtimes,
      dateinit,
      dateend,
      getDayWeek,
      getNumWeek,
      searchload,
      filterListLoads,
      modalEle,
      modalEleTrash,
      modalEleWeekDriver,
      selected_load,
      viewLoad,
      searchweek,
      search_note,
      searchweeki,
      changeWeekEnd,
      drivers_notes,
      save_editload,
      weekDriverItem,
      loadStatus,
      getColorStatus,
      filter_view,
      getLastTrip,
      getRuteX,
      xscoreweek,
      objscorewk,
    };
  },
};
</script>
